<template>
  <div class="media-block">
    <div
      class="image-viewer animate__animated animate__faster d-flex justify-center"
      ref="imageViewer"
      :class="{
        'image-viewer__active animate__zoomIn': viewer.show,
        'image-viewer__active animate__zoomOut': !viewer.show,
      }"
      @click="closeViewer"
    >
      <img
        v-if="viewer.image.length > 0"
        :src="viewer.image"
        ref="imageViewerImg"
        class="image-viewer__image"
      />
    </div>
    <div class="wrap-video" key="video">
      <template v-for="vItem of videoItems">
        <!-- <video
        class="video-item"
        controls
        :src="vItem.fileBase64"
        :poster="vItem.preview"
        :key="vItem.id"
      ></video> -->

        <v-img
          v-if="vItem.preview.length > 0"
          :src="vItem.preview"
          :key="vItem.id"
          class="video-item"
          content-class="video-item__content d-flex align-center justify-center"
        >
          <v-btn
            rounded
            :ripple="false"
            color="primary"
            class="video-item__content__access-btn"
          >
            {{ $vuetify.lang.t("$vuetify.viewOnlyAppLabel") }}
          </v-btn>
        </v-img>
      </template>
    </div>
    <div class="gallery" v-if="imageItems.length > 1" ref="mediaGalery">
      <div
        class="gallery-panel"
        v-for="imgItem of imageItems"
        :key="imgItem.id"
        @click="startViewer(imgItem, $event)"
      >
        <v-img
          width="100%"
          :src="mediaSource(imgItem)"
          class="d-flex align-center"
        >
          <progress-circular v-if="imgItem.isLoading" />
        </v-img>
      </div>
    </div>
    <v-img
      v-else
      v-for="imgItem of imageItems"
      :key="imgItem.id"
      height="250"
      width="100%"
      :src="mediaSource(imgItem)"
      @click="startViewer(imgItem, $event)"
      class="mb-2 image-single d-flex align-center"
    >
      <progress-circular v-if="imgItem.isLoading" />
    </v-img>
  </div>
</template>

<script>
import { MEDIA_FILE_TYPE } from "@/utils/constants";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";
import chunk from "lodash/chunk";
const NOT_FOUND_IMAGE_PATH = require("../../assets/not-found.png");

export default {
  components: { ProgressCircular },
  name: "MediaBlock",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.videoItems = this.items.filter((item) => {
      return item.type === this.mediaTypes.VIDEO;
    });
    this.imageItems = this.items.filter((item) => {
      return item.type === this.mediaTypes.IMAGE;
    });
  },
  data: function () {
    return {
      mediaTypes: MEDIA_FILE_TYPE,
      videoItems: [],
      imageItems: [],
      viewer: {
        show: false,
        image: "",
        currentId: "",
      },
    };
  },
  methods: {
    mediaSource(_item) {
      return _item.fileBase64.length == 0 && _item.preview.length > 0
        ? _item.preview
        : _item.fileBase64.length > 0
        ? _item.fileBase64
        : NOT_FOUND_IMAGE_PATH;
    },
    startViewer(_item, _ev) {
      if (this.viewer.currentId == _item.id) {
        this.closeViewer();
        return false;
      } else {
        this.closeViewer();
      }

      const chunked = chunk(this.imageItems, 2);
      const rowIndex = chunked.findIndex((s) =>
        s.some((o) => o.id === _item.id)
      );

      const deltaTop = this.$vuetify.breakpoint.mobile ? 65 : 100;
      let scrollPosition;
      let parentItem = _ev.target.parentNode.parentNode.parentNode;

      let viewerPosition = rowIndex > 0 ? (rowIndex - 1) * 220 : 0;

      if (rowIndex === 0) {
        parentItem = _ev.target.parentNode;
        let mediaBlockTop = parentItem.getBoundingClientRect().top;
        scrollPosition = mediaBlockTop - deltaTop;
      } else {
        if (rowIndex == chunked.length - 1 && rowIndex > 1) {
          viewerPosition -= 220;
        }
        let mediaBlockTop = parentItem.getBoundingClientRect().top;

        scrollPosition = mediaBlockTop + viewerPosition - deltaTop;
      }

      if (_item.fileBase64.length > 0) {
        this.viewer.show = true;
        this.viewer.image = _item.fileBase64;
        this.viewer.currentId = _item.id;

        this.$refs.imageViewer.style.top = `${viewerPosition}px`;
        window.scrollBy({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    },
    closeViewer() {
      this.viewer.show = false;
      this.viewer.image = "";
      this.viewer.currentId = "";
    },
  },
};
</script>

<style lang="scss">
$borderRadius: 10px;
.media-block {
  border-radius: $borderRadius;
  width: 100%;

  .image-viewer {
    width: inherit;
    max-height: inherit;
    height: inherit;
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.75);
    border-radius: $borderRadius;
    display: none;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    &__image {
      max-height: 720px;
      border-radius: $borderRadius;
      max-width: $post-width;
      object-fit: cover;

      @media screen and (min-width: $post-width-xs) and (max-width: 599px) {
        width: $post-width-xs;
      }
    }

    &__active {
      position: absolute;
      z-index: 2;
    }
  }

  .wrap-video {
    .v-image:first-child {
      border-top-left-radius: $borderRadius !important;
      border-top-right-radius: $borderRadius !important;
    }

    .video-item {
      width: 100%;
      &__content {
        background-color: rgba(0, 0, 0, 0.4);
        &__access-btn {
          letter-spacing: initial;
          text-transform: initial;
        }
      }
    }
  }

  .video-item:not(:first-child) {
    margin-top: -8px;
  }

  .v-image__image {
    background-position: initial !important;
  }

  .image-single {
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    cursor: pointer;
  }

  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    grid-gap: 1px;

    .gallery-panel {
      max-height: 220px;
      .v-image {
        max-height: inherit;
        cursor: pointer;
      }
    }
  }

  .gallery-panel:first-child {
    .v-image {
      border-top-left-radius: $borderRadius !important;
    }
  }
  .gallery-panel:nth-child(2) {
    .v-image {
      border-top-right-radius: $borderRadius !important;
    }
  }
}
</style>