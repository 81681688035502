<template>
  <v-tooltip v-model="showTooltip" top>
    <template v-slot:activator="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        color="secondary"
        :class="localClassBtn"
        @click="onClick"
        ><v-icon small> mdi-content-copy </v-icon>
      </v-btn>
    </template>
    {{ $vuetify.lang.t("$vuetify.copiedLabel") }}
  </v-tooltip>
</template>

<script>
export default {
  name: "CopyButton",
  props: {
    classBtn: {
      type: String,
      default: "",
    },
    copyText: {
      type: String,
      required: true,
    },
  },
  created() {
    this.localClassBtn += this.classBtn;
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
  data: function () {
    return {
      localClassBtn: "copy-button ",
      showTooltip: false,
      timerId: "",
    };
  },
  methods: {
    onClick: function () {
      this.copyToClipboard(this.copyText)
        .then(() => {
          this.showTooltip = true;
          this.timerId = setTimeout(() => {
            this.showTooltip = false;
          }, 1000);
        })
        .catch();
    },
    copyToClipboard(textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.readOnly = true;
        textArea.contentEditable = true;
        textArea.style.height = "1px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
  },
};
</script>