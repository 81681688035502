<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex flex-column align-center post-wrapper">
        <header-logo />
        <template v-if="post && isFound">
          <post-item :post="post" class="d-flex flex-column mt-6" />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="primary"
                fixed
                bottom
                right
                fab
                large
                v-bind="attrs"
                v-on="on"
                @click="goToChannel"
              >
                <v-icon> mdi-share-outline </v-icon>
              </v-btn>
            </template>
            {{ $vuetify.lang.t("$vuetify.toChannelLabel") }}
          </v-tooltip>
        </template>
        <progress-circular v-else-if="isLoading && isFound" />
        <not-found v-else-if="!isFound">{{
          $vuetify.lang.t("$vuetify.postNotFoundLabel")
        }}</not-found>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderLogo from "@/components/HeaderLogo.vue";
import PostItem from "@/components/posts/PostItem.vue";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";
import NotFound from "@/components/ui/NotFound.vue";
import { channelMixin } from "@/mixins/channelMixin";
export default {
  name: "ChannelSinglePost",
  components: { PostItem, ProgressCircular, NotFound, HeaderLogo },
  mixins: [channelMixin],
  beforeMount() {
    this.isLoading = true;
    this.searchPost(this.$route.params.postId.trim());
  },
  data: function () {
    return {
      isLoading: false,
      isFound: true,
      post: null,
    };
  },
  methods: {
    goToChannel() {
      this.$store.dispatch("clearPosts");
      this.$router.replace({
        name: "ChannelDetails",
        params: { channelId: this.post.channelId },
      });
    },
    searchPost(postID) {
      this.$store
        .dispatch("getPostById", postID)
        .then((data) => {
          this.post = data;
        })
        .catch(() => {
          this.isFound = false;
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.post-wrapper{
  margin-bottom: 82px;
}

</style>