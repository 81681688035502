export var channelMixin = {
    methods:{
        mixinStoreChannel(channel){
            this.$store.dispatch("setLastChannelId", channel.id);
            this.$store.dispatch("setChannel", channel);
            if (channel.avatarRefId !== null) {
              this.$store.dispatch("loadAvatarImage", channel.avatarRefId);
            }
        }
    }
}