<template>
  <div class="d-flex justify-center align-center px-4 py-4">
    <v-progress-circular
      indeterminate
      color="primary"
      size="50"
      width="1"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "ProgressCircular",
};
</script>