<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <header-logo />
        <template v-if="!isLoading && isFound">
          <div class="channel-data d-flex flex-column align-center">
            <avatar-channel :channel="channel" class="pt-5" />
            <div class="channel-data__alias">{{ channel.alias }}</div>
            <div class="channel-data__subscribers color-note">
              {{ channel.subscribersCount }} {{ $vuetify.lang.t("$vuetify.subscribersLabel") }}
            </div>
            <div class="channel-data__id d-inline-flex align-center">
              {{ $vuetify.lang.t("$vuetify.tgIdLabel") }}
              <span class="channel-data__id__number ml-2">{{
                channel.id
              }}</span>
              <copy-button
                classBtn="channel-data__id__copy"
                :copyText="channel.id"
              />
            </div>
            <div class="channel-data__description">
              {{ channel.description }}
            </div>
          </div>
          <div class="action-buttons d-flex flex-column align-center mt-8">
            <download-button
              classBtn="action-buttons__button action-buttons__button__download py-2"
              sizeIcon="24"
              :large="true"
            />
            <v-btn
              rounded
              outlined
              large
              class="action-buttons__button action-buttons__button__preview mt-5"
              @click="goToPreview()"
            >
              {{ $vuetify.lang.t("$vuetify.previewChannelLabel") }}
            </v-btn>
          </div>
        </template>
        <progress-circular v-else-if="isLoading && isFound" />
        <not-found v-else-if="!isFound" />
      </v-col>
      <v-col cols="1"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AvatarChannel from "@/components/channel/AvatarChannel.vue";
import { CHANNEL_AVATAR_SIZE } from "@/utils/constants";
import HeaderLogo from "@/components/HeaderLogo.vue";
import DownloadButton from "@/components/buttons/DownloadButton.vue";
import CopyButton from "@/components/buttons/CopyButton.vue";
import { StatusResponse } from "@/backend/api";
import DataService from "@/backend/services/dataService";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";
import { mapGetters } from "vuex";
import NotFound from "@/components/ui/NotFound.vue";
import { channelMixin } from "@/mixins/channelMixin";

export default {
  name: "SplashChannel",
  components: {
    AvatarChannel,
    HeaderLogo,
    DownloadButton,
    CopyButton,
    ProgressCircular,
    NotFound,
  },
  mixins: [channelMixin],
  async beforeMount() {
    const lastId = this.$store.getters["lastId"];
    const queryChannelId = this.$route.params.channelId.trim();
    this.isLoading = true;
    if (lastId !== queryChannelId) {
      this.searchChannel(queryChannelId);
    } else {
      this.isLoading = false;
    }
  },
  data: function () {
    return {
      avatarSize: CHANNEL_AVATAR_SIZE,
      isLoading: false,
      isFound: true,
    };
  },
  methods: {
    goToPreview() {
      this.$router.push({ path: `/${this.channel.id}/details` });
    },
    searchChannel(channelID) {
      DataService.searchChannel(channelID)
        .then((data) => {
          if (data.responseStatus == StatusResponse.OK) {
            this.mixinStoreChannel(data.data.channel);
          } else {
            this.isFound = false;
          }
        })
        .catch(() => {
          this.isFound = false;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    ...mapGetters(["channel"]),
  },
};
</script>

<style lang="scss" scoped>
.channel-data {
  margin-top: 89px;

  &__alias {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin-top: 29px;
    word-break: break-all;
  }

  &__subscribers {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 6px;
  }

  &__id {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 3px;

    &__number {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
  }

  &__description {
    max-width: 400px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 17px;
  }
}
.action-buttons {
  &__button {
    width: 250px;
    height: 50px !important;
    text-transform: inherit !important;
  }
  &__button__download {
    background-color: var(--primary-color) !important;
    color: white;
  }
  &__button__preview {
    border: 1px solid !important;
    border-color: var(--light-grey-color) !important;
  }
}
</style>