<template>
  <div class="header d-inline-flex justify-center align-center">
    <v-img
      :src="require('../assets/tg-logo.png')"
      class="d-inline-flex"
      contain
      height="25"
      width="25"
    />
    <div
      class="d-inline-flex ml-2 font-weight-light color-primary header__label"
    >
      {{ $vuetify.lang.t("$vuetify.tgLabel") }}
    </div>
  </div>
</template>

<script>
export default {
    name:"HeaderLogo"
};
</script>

<style lang="scss" scoped>
.header {
  &__label {
    font-size: 18px;
  }
}
</style>