<template>
  <v-card class="channel-card" outlined v-if="!isLoading">
    <div class="channel-card__header d-inline-flex">
      <avatar-channel :channel="channel" :size="65" :fontSize="24" />
      <div class="channel-card__header-info d-flex-column pt-3">
        <div class="channel-card__header-info__alias">
          {{ channel.alias }}
        </div>
        <div class="channel-card__header-info__id">
          {{ $vuetify.lang.t("$vuetify.tgIdLabel") }}
          <span class="channel-card__header-info__id__number ml-2">{{
            channel.id
          }}</span>
          <copy-button
            classBtn="channel-card__header-info__id__copy"
            :copyText="channel.id"
          />
        </div>
      </div>
    </div>
    <div class="channel-card__body">
      <div class="mt-3">
        <div class="channel-card__body__subscribers d-inline-flex">
          <span class="channel-card__body__subscribers__count mr-1"
            >{{ channel.subscribersCount }}
          </span>
          {{ $vuetify.lang.t("$vuetify.subscribersLabel") }}
        </div>
        <div class="channel-card__body__posts d-inline-flex ml-5">
          <span class="channel-card__body__posts__count mr-1"
            >{{ channel.postsCount }}
          </span>
          {{ $vuetify.lang.t("$vuetify.postsLabel") }}
        </div>
      </div>
      <!-- <div class="mt-3">
        <div class="channel-card__body__params-key d-inline-flex">{{ $vuetify.lang.t("$vuetify.channelAuthorLabel") }}</div>
        <div class="channel-card__body__params-value d-inline-flex ml-5">
          {{ channel.admin }}
        </div>
      </div> -->
      <div class="mt-3">
        <div class="channel-card__body__params-key d-inline-flex">{{ $vuetify.lang.t("$vuetify.channelCategoryLabel") }}</div>
        <div class="channel-card__body__params-value d-inline-flex ml-5">
          {{ channel.category }}
        </div>
      </div>
      <div class="mt-3">
        <div class="channel-card__body__params-key d-inline-flex">{{ $vuetify.lang.t("$vuetify.channelLanguageLabel") }}</div>
        <div class="channel-card__body__params-value d-inline-flex ml-5">
          {{ channel.languageCode }}
        </div>
      </div>
      <div class="mt-3">
        <div class="channel-card__body__params-key d-inline-flex">
          {{ $vuetify.lang.t("$vuetify.channelDescriptionLabel") }}
        </div>
        <div class="channel-card__body__params-value d-inline-flex ml-5"></div>
      </div>
      <div class="mt-2 channel-card__body__description">
        {{ channel.description }}
      </div>
    </div>
    <div class="channel-card__footer mt-3">
      <download-button
        classBtn="channel-card__footer__download ml-3"
        sizeIcon="24"
      />
      <div class="channel-card__footer__note justify-center mt-1">
        {{ $vuetify.lang.t("$vuetify.joinToLabel") }}
      </div>
    </div>
  </v-card>
  <progress-circular v-else />
</template>

<script>
import { CHANNEL_AVATAR_SIZE } from "@/utils/constants";
import AvatarChannel from "@/components/channel/AvatarChannel.vue";
import DownloadButton from "@/components/buttons/DownloadButton.vue";
import CopyButton from "@/components/buttons/CopyButton.vue";
import { mapGetters } from "vuex";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";

export default {
  name: "ChannelDetailsCard",
  components: { AvatarChannel, DownloadButton, CopyButton, ProgressCircular },
  data: function () {
    return {
      avatarSize: CHANNEL_AVATAR_SIZE,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["channel"]),
  },
};
</script>

<style lang="scss" scoped>
.channel-card {
  @media screen and (min-width: 340px) and (max-width: 599px) {
    min-width: 340px;
    max-width: 340px;
    height: inherit;
    margin-left: 0px;
  }
  padding: 20px 0px 20px 20px;
  min-width: 330px;
  max-width: 330px;
  border-color: var(--light-grey-color);
  border-radius: 10px;
  margin-left: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  &__header-info {
    margin-left: 10px;
    &__alias {
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      word-break: break-all;
    }
    &__id {
      color: var(--note-color);

      &__number {
        color: black;
      }
    }
  }

  &__body {
    &__subscribers,
    &__posts {
      &__count {
        font-weight: 600;
      }
    }
    &__params-key {
      min-width: 70px;
      color: var(--note-color);
    }
    &__params-value {
      margin-left: 40px;
    }
    &__description {
      line-height: 24px;
    }
  }

  &__footer {
    &__download {
      width: 260px;
      letter-spacing: initial;
    }
    &__note {
      color: var(--note-color);
      font-weight: 400;
      font-size: 14px;
      padding-right: 20px;
      text-align: center;
    }
  }
}
</style>