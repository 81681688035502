<template>
  <v-container fluid class="pa-0 details-body">
    <div class="toolbar d-flex align-center">
      <v-row no-gutters>
        <v-col
          sm="6"
          md="6"
          lg="5"
          xl="5"
          class="wrap-header-logo d-flex justify-start justify-md-end"
        >
          <header-logo class="toolbar__header-logo mr-5" />
        </v-col>
        <v-col
          sm="6"
          md="6"
          lg="7"
          xl="7"
          class="d-flex justify-start justify-xs-end"
        >
          <v-form>
            <v-text-field
              width="260"
              v-model.trim="searchString"
              prepend-inner-icon="mdi-magnify"
              :placeholder="$vuetify.lang.t(`$vuetify.searchOnChannelLabel`)"
              hide-details="true"
              clearable
              class="py-0 ml-lg-2 toolbar__search-input"
              dense
              solo
              flat
              rounded
              color="primary"
              background-color="sublightgrey"
              v-on:keyup="filterPosts"
              @click:clear="clearSearch"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <template v-if="!isLoading && isFound">
      <div class="channel-details px-3 pt-5">
        <v-row>
          <v-col
            sm="12"
            md="12"
            lg="5"
            xl="5"
            class="channel-details__wrap d-flex justify-sm-center justify-lg-end"
          >
            <channel-details-card class="channel-details__card-item" />
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="7"
            xl="7"
            class="wrap-post-list d-flex justify-sm-center justify-lg-start"
          >
            <posts-list class="channel-details__posts" />
          </v-col>
        </v-row>
      </div>
    </template>
    <progress-circular v-else-if="isLoading && isFound" />
    <not-found v-else-if="!isFound" />
  </v-container>
</template>

<script>
import HeaderLogo from "@/components/HeaderLogo.vue";
import ChannelDetailsCard from "@/components/channel/ChannelDetailsCard.vue";
import PostsList from "@/components/posts/PostsList.vue";
import DataService from "@/backend/services/dataService";
import { StatusResponse } from "@/backend/api";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";
import NotFound from "@/components/ui/NotFound.vue";
import { channelMixin } from "@/mixins/channelMixin";

export default {
  name: "ChannelDetails",
  components: {
    HeaderLogo,
    ChannelDetailsCard,
    PostsList,
    ProgressCircular,
    NotFound,
  },
  mixins: [channelMixin],
  async beforeMount() {
    this.isLoading = true;
    const lastId = this.$store.getters["lastId"];
    if (lastId !== this.$route.params.channelId.trim()) {
      this.searchChannel(this.$route.params.channelId.trim());
    } else {
      this.isLoading = false;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data: function () {
    return {
      searchString: "",
      isLoading: false,
      isFound: true,
    };
  },
  methods: {
    filterPosts() {
      this.$store.dispatch("filterPosts", this.searchString);
    },
    clearSearch() {
      this.searchString = "";
      this.filterPosts();
    },
    searchChannel(channelID) {
      DataService.searchChannel(channelID)
        .then((data) => {
          if (data.responseStatus == StatusResponse.OK) {
            this.mixinStoreChannel(data.data.channel);
          } else {
            this.isFound = false;
          }
        })
        .catch(() => {
          this.isFound = false;
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.details-body {
  background-color: var(--background-light-grey-color);
  height: 100.1%;
}
.toolbar {
  width: 100%;
  height: 60px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;

  &__header-logo {
    margin-top: 3px;
    margin-left: 16px;
  }

  &__search-input {
    color: var(--primary-color) !important;
    caret-color: var(--primary-color) !important;
    margin-top: 2px;
  }
}
.channel-details {
  &__card-item {
    position: sticky;
    top: 80px;
  }
}

.channel-details__wrap {
  @media screen and (min-width: 1264px) {
    align-items: flex-start;
    flex-direction: row;
  }
}
.channel-details__wrap,
.wrap-post-list {
  @media screen and (min-width: 340px) and (max-width: 599px) {
    flex: 0 0 100%;
    justify-content: center;
  }
}

.justify-xs-end {
  @media screen and (min-width: 340px) and (max-width: 599px) {
    justify-content: end !important;
  }
}
</style>