<template>
  <v-card class="post-card" outlined>
    <media-block v-if="post.mediaData.length > 0" :items="post.mediaData" />
    <v-card-title>{{ post.header }}</v-card-title>
    <v-card-text class="post-card__content">{{ post.content }}</v-card-text>
    <v-card-text
      class="post-card__link mt-3 mb-2"
      v-for="(link, index) in post.urls"
      :key="post.id + '_link_' + index"
    >
      <a target="_blank" :href="link">{{ link }}</a>
      <link-preview
        :url="link"
        :showButton="false"
        cardWidth="100%"
        class="post-card__link__preview mt-2"
      >
        <template slot="loading">
          <progress-circular />
        </template>
        <template slot-scope="props">
          <div class="card">
            <img
              class="card-img-top"
              :src="props.img"
              :alt="props.title"
              v-if="props.img != null"
            />
            <div class="card-block">
              <div class="card-title my-3">{{ props.title }}</div>
              <p class="card-text">{{ props.description }}</p>
            </div>
          </div>
        </template>
      </link-preview>
    </v-card-text>
    <v-card-text class="post-card__footer mt-3">
      <v-row>
        <v-col xs="12" sm="6" md="6" class="d-flex align-center justify-start">
          <div class="post-card__footer__metrica-left d-flex align-center">
            <v-icon color="note" class="mr-1"> mdi-cards-heart-outline </v-icon>
            {{ post.likesCount | numberFormat([0, " "]) }}
          </div>
          <!-- <div class="post-card__footer__metrica-left d-flex align-center ml-4">
            <v-icon color="note" size="28" class="mr-1">
              mdi-share-outline
            </v-icon>
            {{ 12 | numberFormat([0, " "]) }}
          </div> -->
        </v-col>
        <v-col xs="12" sm="6" md="6" class="d-flex align-end flex-column">
          <div class="post-card__footer__metrica-right">
            {{ post.viewsCount | numberFormat([0, " "]) }}
            <v-icon size="16" color="note" class="ml-1">
              mdi-eye-outline
            </v-icon>
          </div>
          <div class="post-card__footer__metrica-right">
            {{ post.createDate | dateFormat("DD.MM.YY HH:mm") }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LinkPreview from "@/components/preview/LinkPreview.vue";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";
import MediaBlock from "@/components/media/MediaBlock.vue";
export default {
  name: "PostItem",
  components: { LinkPreview, ProgressCircular, MediaBlock },
  props: {
    post: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.post-card {
  @media screen and (min-width: $post-width-xs) and (max-width: 599px) {
    width: $post-width-xs;
  }
  width: $post-width;
  border-color: var(--light-grey-color);
  border-radius: 10px !important;
  font-weight: 400;

  .v-card__title {
    font-weight: 600;
    padding-bottom: 2px !important;
    line-height: 24px !important;
  }
  .v-card__text:not(:last-child) {
    padding-bottom: 0 !important;
  }
  .v-card__text {
    font-weight: 400;
    padding-top: 0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  &__content{
    white-space: pre-line;
  }

  &__link {
    a {
      color: var(--secondary-color) !important;
    }

    &__preview {
      border-left: 2px solid var(--secondary-color);

      .card {
        width: 100%;
        font-size: 14px;
        padding: 0 10px;

        .card-img-top {
          width: inherit;
          border-radius: 10px;
        }

        .card-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .card-text {
          font-weight: 400;
          line-height: 20px;
          margin-left: 0px;
        }
      }
    }
  }

  &__footer {
    &__metrica-right {
      font-size: 12px;
      line-height: 22px;
    }
  }
}
</style>