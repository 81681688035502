<template>
  <v-btn rounded :large="large" :class="localClassBtn" @click="navigateToStore">
    {{ $vuetify.lang.t("$vuetify.downloadAppLabel") }}
    <v-icon right :size="sizeIcon"> mdi-chevron-right</v-icon>
  </v-btn>
</template>

<script>
import { ANDROID_APP_LINK, IOS_APP_LINK, WEB_APP_LINK } from '@/utils/constants';
export default {
  name: "DownloadButton",
  props: {
    classBtn: {
      type: String,
      default: "",
    },
    sizeIcon: {
      type: String,
      default: "16",
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.localClassBtn += this.classBtn;

    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      this.redirectLink = ANDROID_APP_LINK;
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      this.redirectLink = IOS_APP_LINK;
    }
  },
  data: function () {
    return {
      localClassBtn: "download-button ",
      redirectLink: WEB_APP_LINK,
    };
  },
  methods:{
    navigateToStore(){
      window.open(this.redirectLink);
    }
  }
};
</script>

<style lang="scss" scoped>
.download-button {
  text-transform: none !important;
  background-color: var(--primary-color) !important;
  color: white;
}
</style>