<template>
  <v-img
    :width="avatarSize"
    :height="avatarSize"
    :contain="true"
    v-if="channel.base64Img && channel.base64Img.length > 0"
    :src="channel.base64Img"
    class="avatar-border"
  ></v-img>
  <v-avatar class="avatar-border avatar-text" :size="avatarSize" :style="avatarStyle" v-else>{{
    channel.alias | wordAvatar
  }}</v-avatar>
</template>

<script>
import {
  CHANNEL_AVATAR_SIZE,
  CHANNEL_AVATAR_FONT_SIZE,
} from "@/utils/constants";

export default {
  name: "AvatarChannel",
  props: {
    channel: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: CHANNEL_AVATAR_SIZE,
    },
    fontSize: {
      type: Number,
      default: CHANNEL_AVATAR_FONT_SIZE,
    },
  },
  data: function () {
    return {
      avatarSize: this.size,
    };
  },
  computed: {
    avatarStyle() {
      return "font-size: " + this.fontSize + "px !important;";
    },
  },
};
</script>
<style lang="scss" scoped>
.v-avatar {
  background-color: var(--primary-color);
  color: white;
}
.avatar-border {
  border-radius: 49% 49% 49% 0;
  padding: 5px;
}
.avatar-text {
  font-weight: 200;
}
</style>