<template>
  <div class="pa-0 container-posts">
    <progress-circular v-if="isLoadingPosts" />
    <post-item
      v-else
      v-for="post in channel.posts"
      :post="post"
      :key="post.id"
      class="mb-3"
    />
  </div>
</template>

<script>
import PostItem from "@/components/posts/PostItem.vue";
import { mapGetters } from "vuex";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";

const EVENT_SCROLL_NAME = "scroll";
const SCROLL_DISTANCE = 50;

export default {
  components: { PostItem, ProgressCircular },
  name: "PostsList",
  mounted() {
    window.addEventListener(EVENT_SCROLL_NAME, this.handleScroll, false);
  },
  beforeDestroy() {
    window.removeEventListener(EVENT_SCROLL_NAME, this.handleScroll, false);
  },
  async beforeCreate() {
    const lastId = this.$store.getters["lastId"];
    const posts = this.$store.getters["posts"];
    const queryChannelId = this.$route.params.channelId.trim();

    if (lastId !== queryChannelId || posts.length === 0) {
      this.$store.dispatch("getPosts");
    }
  },
  computed: {
    ...mapGetters(["channel", "isLoadingPosts", "currentPage"]),
  },
  methods: {
    handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - SCROLL_DISTANCE
      ) {
        this.$store.dispatch("getChannelPostsChunk", this.currentPage);
      }
    },
  },
};
</script>