<template>
  <div class="search-form-wrap d-flex align-center justify-center flex-column">
    <header-logo class="mb-12" />
    <v-form class="search-form d-flex align-center justify-center">
      <v-text-field
        v-model="channelID"
        :label="$vuetify.lang.t('$vuetify.channelIdLabel')"
        :disabled="isLoading"
        :error-messages="inputErrors"
        @keydown.enter="searchChannel"
        @keyup="resetError"
        @click:clear="resetError"
        clearable
        class="search-form__channel-id-input mr-2"
      ></v-text-field>
      <v-btn
        class="search-form__submit"
        color="primary"
        :loading="isLoading"
        @click="searchChannel"
        >{{ $vuetify.lang.t("$vuetify.findChannelLabel") }}</v-btn
      >
    </v-form>
    <v-fade-transition duration="100">
      <div class="error-block">
        {{ mainErrorMessage }}
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import HeaderLogo from "@/components/HeaderLogo.vue";
import DataService from "@/backend/services/dataService";
import { StatusResponse } from "@/backend/api";
import { channelMixin } from "@/mixins/channelMixin";
import { MIN_LENGTH } from "@/utils/constants";

export default {
  name: "SearchChannel",
  components: { HeaderLogo },
  mixins: [channelMixin],
  data: function () {
    return {
      channelID: "",
      isLoading: false,
      errorMessages: {
        notFound: this.$vuetify.lang.t("$vuetify.errorNotFound"),
        internalError: this.$vuetify.lang.t("$vuetify.errorInternalError"),
      },
      mainErrorMessage: "",
      inputErrors: [],      
    };
  },
  methods: {
    checkErrors() {
      let errorString = [];

      if (this.channelID.length === 0) {
        errorString.push(
          this.$vuetify.lang.t(
            "$vuetify.fieldMustBeRequired",
            this.$vuetify.lang.t("$vuetify.channelIdLabel")
          )
        );
      }

      if (this.channelID.length < MIN_LENGTH) {
        errorString.push(
          this.$vuetify.lang.t(
            "$vuetify.mustBeNoLens",
            this.$vuetify.lang.t("$vuetify.channelIdLabel"),
            MIN_LENGTH
          )
        );
      }

      return errorString;
    },
    searchChannel() {
      this.inputErrors = this.checkErrors();
      if(this.inputErrors.length > 0) return false;
      
      this.isLoading = true;
      DataService.searchChannel(this.channelID)
        .then((data) => {
          if (data.responseStatus == StatusResponse.OK) {
            this.mixinStoreChannel(data.data.channel);
            this.$router.push({
              name: "SplashChannel",
              params: { channelId: data.data.channel.id },
            });
          } else if (data.responseStatus == StatusResponse.NOT_FOUND) {
            this.mainErrorMessage = this.errorMessages.notFound;
          } else {
            this.mainErrorMessage = this.errorMessages.internalError;
          }
        })
        .catch(() => {
          this.mainErrorMessage = this.errorMessages.internalError;
        })
        .finally(() => (this.isLoading = false));
    },
    resetError() {
      this.mainErrorMessage = "";
      this.inputErrors = [];
    },
  },
};
</script>

<style lang="scss" scoped>
$blockWidth: 350px;
.search-form-wrap {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/search-bg-monochrome.png");
  background-size: cover;

  .search-form {
    width: $blockWidth;

    &__submit {
      text-transform: initial !important;
    }
  }

  .error-block {
    width: $blockWidth;
    color: #ff5252;
    font-size: 12px;
  }
}
</style>